/* App.css */

/* Global Box Sizing and Reset */
*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  background-image: linear-gradient(to bottom, #1e1e2e, #181824);
  color: #e4e4e7;
  font-family: 'Roboto', sans-serif;
  margin: 0;
  padding: 0;
  font-size: 16px;
}

.app-container {
  padding: 80px 20px 20px 20px; /* Added padding-top of 80px */
  max-width: 800px;
  margin: 0 auto;
  text-align: center; /* Center align content */
}

/* Main Logo */
.main-logo {
  width: 80px;
  height: 80px;
  margin-bottom: 10px;
}

/* Beta Header */
.beta-header {
  font-size: 18px;
  font-weight: bold;
  color: #a0aec0;
  text-align: center;
  margin-bottom: 0;
  text-transform: uppercase;
  letter-spacing: 1.2px;
}

/* Analytics Header */
.analytics-header {
  font-size: 28px;
  font-weight: 800;
  text-align: center;
  margin-top: 0;
  margin-bottom: 20px;
  color: #e4e4e7;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  line-height: 1.3;
  position: relative;
}

.analytics-header span {
  color: #4fdcdc;
}

.analytics-header::after {
  content: '';
  position: absolute;
  bottom: -8px;
  left: 50%;
  transform: translateX(-50%);
  width: 100px;
  height: 4px;
  background-color: #4fdcdc;
  border-radius: 4px;
  box-shadow: 0 6px 4px -4px rgba(79, 220, 220, 0.3),
    0 10px 8px -8px rgba(36, 36, 48, 0.6);
}

/* Token List */
.token-list {
  list-style-type: none;
  padding: 0;
  text-align: left; /* Reset text alignment for token list */
}

/* Token Card */
.token-card {
  margin-bottom: 20px;
  background-image: linear-gradient(to bottom, #2c2d40, #222336);
  border-radius: 12px;
  padding: 20px;
  box-sizing: border-box;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
  position: relative;
  transition: box-shadow 0.3s ease, border-color 0.3s ease;
  border: 1px solid transparent;
  cursor: default;
  color: #e4e4e7;
}

.token-card:hover {
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.4);
  border-color: #00b3b3;
}

/* Token Header */
.token-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
  flex-wrap: wrap;
}

/* Token Image and Name */
.token-image-name {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.token-info {
  display: flex;
  flex-direction: column;
  margin-left: 15px;
}

.token-name {
  font-size: 22px;
  color: #ffffff;
  font-weight: 700;
  margin-bottom: 4px;
}

.token-symbol {
  font-size: 16px;
  color: #b0b0b5;
  font-weight: 500;
  margin-bottom: 8px;
}

/* Circular Progress */
.circular-progress {
  position: relative;
  width: 120px; /* Adjust this size as needed */
  height: 120px;
}

.circular-progress svg {
  width: 100%;
  height: 100%;
  transform: rotate(-90deg);
  overflow: visible; /* Ensure that the overflow is visible to prevent clipping */
}

.circular-progress .progress-outer-border,
.circular-progress .progress-inner-border,
.circular-progress .progress-background,
.circular-progress .progress-bar {
  transition: stroke-dashoffset 0.5s ease;
}

.circular-progress .progress-background {
  stroke: rgba(0, 179, 179, 0.2); /* Semi-transparent teal */
}

.circular-progress .progress-outer-border,
.circular-progress .progress-inner-border {
  stroke: #005050; /* Dark teal color */
}

.circular-progress .progress-bar {
  stroke: #00e0e0; /* Brighter teal for the progress */
}

.circular-progress-image {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 80%; /* Adjusted to ensure it fits within the progress circle */
  height: 80%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  /* Optional: Add a subtle box shadow for depth */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

/* Platform Button */
.platform-button {
  display: flex;
  align-items: center;
  background-color: transparent;
  border: 2px solid #00b3b3;
  border-radius: 5px;
  padding: 10px 15px;
  cursor: pointer;
  text-decoration: none;
  transition: background-color 0.3s ease, color 0.3s ease, border-color 0.3s ease;
  font-size: 16px;
  color: #00b3b3;
  margin-top: 10px;
}

.platform-button:hover {
  background-color: #00b3b3;
  color: #1e1e2e;
  border-color: #00b3b3;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

/* Platform Icon Image */
.platform-icon-image {
  width: 28px;
  height: 28px;
  object-fit: contain;
  margin-right: 8px;
  opacity: 0.7;
  filter: none;
  transition: opacity 0.3s ease;
}

.platform-button:hover .platform-icon-image {
  opacity: 1;
  filter: brightness(0) saturate(100%) invert(14%) sepia(18%) saturate(415%) hue-rotate(222deg) brightness(95%) contrast(88%);
}

.platform-name {
  font-size: 16px;
  font-weight: 600;
}

/* Social Links Styles */
.social-links {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  margin-top: 8px;
}

.icon-link {
  display: inline-block;
  width: 28px;
  height: 28px;
}

.icon-image {
  width: 100%;
  height: 100%;
  object-fit: contain;
  opacity: 0.7;
  transition: opacity 0.3s ease;
}

.icon-link:hover .icon-image {
  opacity: 1;
}

/* Adjusted Padding for Description */
.description-container {
  margin-top: 20px;
}

/* Token Description */
.token-description {
  font-size: 15px;
  color: #c0c0c5;
  margin-bottom: 15px;
  line-height: 1.6;
}

/* Token Stats */
.token-stats {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.token-stat-section {
  background-color: rgba(255, 255, 255, 0.05);
  padding: 15px;
  border-radius: 8px;
}

.token-stat-heading {
  font-size: 18px;
  color: #00b3b3;
  margin-bottom: 12px;
  border-bottom: 2px solid #00b3b3;
  padding-bottom: 6px;
}

.token-stat {
  font-size: 15px;
  color: #c0c0c5;
  line-height: 1.5;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 8px;
}

.token-label {
  font-weight: 600;
  color: #00b3b3;
  margin-right: 5px;
}

/* Copy Button Styling */
.copy-button {
  background-color: transparent;
  border: 1px solid #00b3b3;
  border-radius: 4px;
  color: #f4f4f9;
  cursor: pointer;
  font-size: 13px;
  transition: background-color 0.3s, color 0.3s;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 5px 8px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: 5px;
}

.copy-button:hover {
  background-color: #00b3b3;
  color: #1e1e2e;
}

/* Expand Link */
.expand-link {
  display: inline-flex;
  align-items: center;
  margin-left: 5px;
}

.expand-icon {
  width: 16px;
  height: 16px;
  opacity: 0.7;
  transition: opacity 0.3s ease;
}

.expand-link:hover .expand-icon {
  opacity: 1;
}

/* Token Details */
.token-details {
  margin-top: 15px;
}

.details-summary {
  display: inline-flex;
  align-items: center;
  font-weight: 600;
  cursor: pointer;
  color: #00b3b3;
  font-size: 15px;
}

.details-summary:hover {
  color: #00e0e0;
}

.details-summary .details-arrow {
  margin-left: 5px;
  border: solid #00b3b3;
  border-width: 0 2px 2px 0;
  padding: 3px;
  transform: rotate(45deg);
  transition: transform 0.2s ease, border-color 0.2s;
}

.token-details[open] .details-summary .details-arrow {
  transform: rotate(-135deg);
  border-color: #00e0e0;
}

.token-details div {
  margin-top: 10px;
  padding-left: 20px;
  color: #c0c0c5;
}

/* Responsive Styling */
@media (max-width: 768px) {
  body {
    font-size: 14px;
  }

  .token-card {
    padding: 15px;
  }

  .token-header {
    flex-direction: column;
    align-items: flex-start;
  }

  .token-image-name {
    flex-direction: row;
    align-items: center;
    margin-bottom: 15px;
  }

  .token-info {
    margin-left: 15px;
    flex: 1;
  }

  .token-name {
    font-size: 20px;
  }

  .token-symbol {
    font-size: 16px;
  }

  .platform-button {
    padding: 8px 12px;
    font-size: 14px;
    margin-top: 10px;
  }

  .platform-icon-image {
    width: 24px;
    height: 24px;
    margin-right: 8px;
  }

  .platform-name {
    font-size: 14px;
  }

  .token-description {
    font-size: 14px;
  }

  .token-stat {
    font-size: 14px;
  }

  .copy-button {
    font-size: 12px;
    padding: 4px 6px;
  }

  .details-summary {
    font-size: 14px;
  }

  .circular-progress {
    width: 100px;
    height: 100px;
  }
}

/* Header and Footer Styles */

/* Full Width Wrapper */
.full-width-wrapper {
  width: 100%;
  overflow-x: hidden;
}

/* Sticky Header Styling */
.sticky-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 60px;
  background-image: linear-gradient(to bottom, #2c2d40, #222336);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

.header-logo {
  height: 40px;
  width: auto;
}

.header-buttons {
  display: flex;
  gap: 15px;
  align-items: center;
}

/* Social Icons */
.social-icon {
  width: 36px;
  height: 36px;
  cursor: pointer;
  display: flex;
  align-items: center;
  opacity: 0.5;
  transition: opacity 0.3s ease;
}

.social-icon:hover {
  opacity: 1;
  cursor: pointer;
}

/* Buy Suifox Button */
.buy-suifox-button {
  background-color: transparent;
  color: #c2185b;
  border: 2px solid #c2185b;
  border-radius: 5px;
  padding: 10px 15px;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease, border-color 0.3s ease;
  font-size: 18px;
  text-align: center;
  font-weight: bold;
  box-sizing: border-box;
  margin-left: 5px;
  text-decoration: none;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.buy-suifox-button:hover {
  background-color: #c2185b;
  color: #1e1e2e;
  border-color: #c2185b;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  cursor: pointer;
}

/* Tooltip Styling */
.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 100px;
  background-color: #2c2d40;
  color: #f4f4f9;
  text-align: center;
  border-radius: 5px;
  padding: 5px 0;
  font-size: 12px;
  position: absolute;
  z-index: 1;
  bottom: -35px;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0.75;
  transition: opacity 0.3s;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 0.75;
}

/* Footer */
.footer {
  padding: 20px 0;
  color: rgba(228, 228, 231, 0.5);
  font-size: 14px;
  width: 100%;
  margin-top: 20px;
}

.footer-content {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.footer-left,
.footer-right {
  display: flex;
  flex-direction: column;
}

.footer-left p {
  margin: 5px 0;
  opacity: 0.5;
  transition: opacity 0.3s ease;
}

.footer-left p:hover {
  opacity: 1;
}

.footer-left a {
  color: #4fdcdc;
  text-decoration: none;
  opacity: 0.7;
  transition: color 0.3s ease, opacity 0.3s ease;
}

.footer-left a:hover {
  color: #4fdcdc;
  opacity: 1;
  text-decoration: underline;
}

.footer-links {
  list-style: none;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  margin: 0;
}

.footer-links li {
  margin: 5px 15px;
}

.footer-links a {
  color: rgba(228, 228, 231, 0.5);
  text-decoration: none;
  font-size: 14px;
  opacity: 0.5;
  transition: color 0.3s ease, opacity 0.3s ease;
}

.footer-links a:hover {
  color: #4fdcdc;
  opacity: 1;
}
